import { UseBoundStore, StoreApi, create } from 'zustand';

interface IProps {
  selectedItem: string;
}

// contexto zustand reponsável pela abertura do botão flutuante na página de busca dos planos
export const useRotateSliderValue: UseBoundStore<StoreApi<IProps>> = create(
  (set, get) => ({
    selectedItem: 'gestao_light'
  }),
);
