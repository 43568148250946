export const PlanoInstitucionalLightIcon = () => {
  return (
  <svg width="422" height="387" viewBox="0 0 422 387" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.666016" y="33" width="396" height="342" rx="8" fill="url(#paint0_linear_3035_62349)"/>
  <path d="M258.333 173.333L258.333 96L161.667 96L161.667 134.667L123 134.667L123 270L200.333 270L200.333 231.333L219.667 231.333L219.667 270L297 270L297 173.333L258.333 173.333ZM161.667 250.667L142.333 250.667L142.333 231.333L161.667 231.333L161.667 250.667ZM161.667 212L142.333 212L142.333 192.667L161.667 192.667L161.667 212ZM161.667 173.333L142.333 173.333L142.333 154L161.667 154L161.667 173.333ZM200.333 212L181 212L181 192.667L200.333 192.667L200.333 212ZM200.333 173.333L181 173.333L181 154L200.333 154L200.333 173.333ZM200.333 134.667L181 134.667L181 115.333L200.333 115.333L200.333 134.667ZM239 212L219.667 212L219.667 192.667L239 192.667L239 212ZM239 173.333L219.667 173.333L219.667 154L239 154L239 173.333ZM239 134.667L219.667 134.667L219.667 115.333L239 115.333L239 134.667ZM277.667 250.667L258.333 250.667L258.333 231.333L277.667 231.333L277.667 250.667ZM277.667 212L258.333 212L258.333 192.667L277.667 192.667L277.667 212Z" fill="white"/>
  <rect x="22.666" y="12" width="396" height="342" rx="8" stroke="url(#paint1_linear_3035_62349)" stroke-width="6"/>
  <defs>
  <linearGradient id="paint0_linear_3035_62349" x1="92.241" y1="84.3" x2="243.134" y2="367.067" gradientUnits="userSpaceOnUse">
  <stop stop-color="#D2EFFA "/>
<stop offset="1" stop-color="#89E2FA"/>
  </linearGradient>
  <linearGradient id="paint1_linear_3035_62349" x1="114.241" y1="63.3" x2="265.134" y2="346.067" gradientUnits="userSpaceOnUse">
  <stop stop-color="#D2EFFA"/>
  <stop offset="1" stop-color="#004A80"/>
  </linearGradient>
  </defs>
  </svg>

  )
}
