import styled from "styled-components";

export const Container = styled.div`
  .gallery {
    width: 100%;
    margin-top: -3rem;
  }

  .gallery-container {
    display: flex;
    height: 400px;
    margin: 0 auto;
    max-width: 1000px;
    position: relative;
    align-items: center;
    transform: scale(0.8);

    @media (max-width: 1200px) {
      transform: scale(0.7);
    }
  }

  .gallery-item {
    z-index: 0;
    opacity: 0;
    width: 134.211px;
    height: 115.909px;
    position: absolute;
    border-radius: 15px;
    background-size: contain;
    transition: all 0.3s ease-in-out;
  }

  .gallery-item-1 {
    left: 15%;
    opacity: 0.4;

    transform: translateX(-450%);
  }

  .gallery-item-2,
  .gallery-item-4 {
    z-index: 1;
    opacity: 0.8;
    width: 243px;
    height: 211.026px;
  }

  .gallery-item-2 {
    left: 30%;
    transform: translateX(-190%);
  }

  .gallery-item-3 {
    left: 50%;
    z-index: 2;
    opacity: 1;
    width: 396px;
    height: 342px;
    transform: translateX(-50%);
  }

  .gallery-item-4 {
    left: 70%;
    transform: translateX(90%);
  }

  .gallery-item-5 {
    left: 85%;
    opacity: 0.4;
    transform: translateX(350%);
  }

  .gallery-controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4rem !important;
  }


  .gallery-controls-previous {
    position: absolute;
  }

  .gallery-controls-previous::before {
    top: 25%;
    width: 5px;
    content: "";
    height: 5px;
    left: -500px;
    padding: 10px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    border: solid #004a80;
    border-width: 0 5px 5px 0;
    transition: left 0.15s ease-in-out;
    transform: rotate(135deg) translateY(-50%) translateX(-100%);

    @media (max-width: 1200px) {
      left: -400px;
    }
  }

  .gallery-controls-next {
    position: relative;
  }

  .gallery-controls-next::before {
    top: 45%;
    width: 5px;
    content: "";
    height: 5px;
    padding: 10px;
    right: -500px;
    cursor: pointer;
    position: relative;
    border: solid #004a80;
    display: inline-block;
    border-width: 0 5px 5px 0;
    transition: left 0.15s ease-in-out;
    transform: rotate(-45deg) translateY(-50%);

    @media (max-width: 1200px) {
      right: -400px;
    }
  }

  .gallery-nav {
    padding: 0;
    width: 100%;
    bottom: -15px;
    display: flex;
    list-style: none;
    position: absolute;
    justify-content: center;
  }

  .gallery nav li {
    width: 10px;
    height: 10px;
    margin: 0 16px;
    border-radius: 50%;
    background-color: #ccc;
  }

  .gallery-nav li.gallery-item-selected {
    background-color: #555;
  }
`;
