export const PlanoOrcamentarioLightIcon = () => {
  return (

<svg width="422" height="387" viewBox="0 0 422 387" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.666016" y="33" width="396" height="342" rx="8" fill="url(#paint0_linear_3035_62254)"/>
<rect x="22.666" y="12" width="396" height="342" rx="8" stroke="url(#paint1_linear_3035_62254)" stroke-width="6"/>
<g clip-path="url(#clip0_3035_62254)">
<path d="M173.595 235.884H187.02V225.97H197.76C200.147 225.97 202.049 225.089 203.466 223.327C204.883 221.564 205.591 219.581 205.591 217.378V192.098C205.591 189.711 204.883 187.673 203.466 185.984C202.049 184.295 200.147 183.45 197.76 183.45H167.554V168.248H205.591V155.03H187.02V145.116H173.595V155.03H162.855C160.468 155.03 158.417 155.911 156.702 157.673C154.986 159.436 154.129 161.501 154.129 163.868V188.932C154.129 191.299 154.986 193.18 156.702 194.576C158.417 195.971 160.468 196.669 162.855 196.669H192.166V212.752H154.129V225.97H173.595V235.884ZM246.537 228.173L259.962 214.955H233.112L246.537 228.173ZM233.112 168.248H259.962L246.537 155.03L233.112 168.248ZM132.425 261C128.845 261 125.712 259.678 123.028 257.034C120.342 254.391 119 251.306 119 247.781V133.219C119 129.694 120.342 126.609 123.028 123.966C125.712 121.322 128.845 120 132.425 120H284.575C288.155 120 291.287 121.322 293.973 123.966C296.658 126.609 298 129.694 298 133.219V247.781C298 251.306 296.658 254.391 293.973 257.034C291.287 259.678 288.155 261 284.575 261H132.425ZM132.425 247.781H284.575V133.219H132.425V247.781Z" fill="white"/>
</g>
<defs>
<linearGradient id="paint0_linear_3035_62254" x1="92.241" y1="84.3" x2="243.134" y2="367.067" gradientUnits="userSpaceOnUse">
<stop stop-color="#D2EFFA "/>
<stop offset="1" stop-color="#89E2FA"/>
</linearGradient>
<linearGradient id="paint1_linear_3035_62254" x1="114.241" y1="63.3" x2="265.134" y2="346.067" gradientUnits="userSpaceOnUse">
<stop stop-color="#D2EFFA"/>
<stop offset="1" stop-color="#004A80"/>
</linearGradient>
<clipPath id="clip0_3035_62254">
<rect width="173" height="147" fill="white" transform="translate(123 120)"/>
</clipPath>
</defs>
</svg>
  )
}
