import React from "react";
import { useRouter } from "next/router";
import { InteractiveMapIcon } from "./map";
import { ButtonFidMore } from "../Banner/styles";
import { Container, CardContainer } from "./styles";

export const InteractiveMap: React.FC = () => {
  const route = useRouter();

  return (
    <Container>
      <div className="col-1">
        <InteractiveMapIcon />
      </div>
      <div className="col-2">
        <CardContainer>
          <div className="title_wrapper">
            <p>Mapa interativo</p>
          </div>
          <div className="description_wrapper">
            <p>
              Mapa Interativo da cidade do Rio de Janeiro, apresentando os
              Programas, Projetos e Produtos/Entregas que impulsionam o
              desenvolvimento estratégico da metrópole.
            </p>
          </div>
          <div className="button_wrapper">
            <ButtonFidMore onClick={() => route.push('/mapa_interativo')}>
              <p>Saiba mais sobre o mapa</p>
            </ButtonFidMore>
          </div>
        </CardContainer>
      </div>
    </Container>
  );
};
