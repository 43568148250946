import React from "react";
import { useRouter } from "next/router";
import { IResponsePlans } from "@/services/endpoints/plans";
import { Container, ContainerBody, ContainerTitle } from "./styles";

interface Iprops {
  plano: IResponsePlans;
}

export const Card = React.memo(({ plano }: Iprops) => {
  const router = useRouter();

  const handleNavigate = React.useCallback((id: number) => {
    router.push(`/plano/${id}`);
  }, [router]);

  return (
    <Container onClick={() => handleNavigate(plano?.id)}>
      <ContainerTitle status={plano?.status}>
        <h1 className="resizeable-accessibility">{plano?.titulo}</h1>
      </ContainerTitle>
      <ContainerBody>
        {plano?.svg}
        <p style={{ height: '190px', maxHeight: '200px', overflow: 'hidden' }} className="resizeable-accessibility">{plano?.descricao}</p>
      </ContainerBody>
    </Container>
  );
});
