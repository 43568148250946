import styled from "styled-components";


export const Container = styled.div`
  width: 77%;
  display: flex;
  padding: 90px 0 0 0;

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    display: flex;
    clip-path: none;
    padding: 90px 24px;
    flex-direction: column;
  }
`;

export const Diagonal = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const Description = styled.p`
  width: 72%;
  font-size: 22px;
  font-weight: 400;
  margin-top: 24px;
  line-height: 120%;
  font-style: normal;
  color: var(--primary-text-black, #0b0c0c);
`;
export const ContainerDescriptions = styled.div`
  width: 57%;
  height: 100%;
  margin-left: 1rem;
  transform: scale(.8);

  a {
    text-decoration: none;
  }
  h1 {
    color: ${(props) => props.theme.colors.secundary};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 38.4px */
  }
  p {
    color: ${(props) => props.theme.colors.text};
  }

  @media (max-width: 900px) {
    width: 100%;
    margin-bottom: 8%;

    p {
      width: 100%;
    }
  }
`;

export const ContainerPlanos = styled.div`
  width: 56%;
  height: 100%;
  display: flex;
  position: relative;
  transform: scale(.8);
  flex-direction: column;
  
  @media (max-width: 900px) {
    width: 100%;
  }
`;


export const ButtonFidMore = styled.div`
  display: flex;
  padding: 20px 22px;
  text-align: center;

  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 40px;
  width: 50%;
  height: 65px;
  background: ${(props) => props.theme.colors.secundary};
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
  text-align: center;
  transition: background-color 0.3s;

  p {
    color: ${(props) => props.theme.colors.primary};
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 24.7px */
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: #89e2fa;
    p {
      color: #004a80;
    }
  }

  svg path {
    fill: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const InverterWrapper = styled.div`
  display: flex;
`;

export const Main = styled.main`
  width: fit-content;
  margin-top: -16rem;
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
    clip-path: none;
    display: flex;
    flex-direction: column;
  }
`;