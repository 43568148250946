import styled from "styled-components";

export const Container = styled.div`
  z-index: 1;
  width: 100%;
  display: flex;
  margin-top: -2rem;
  position: relative;
  flex-direction: column;

  .content_wrapper {
  }

  .title_wrapper {
    display: flex;
    justify-content: left;

    p {
      color: var(--primary-text-black, #0b0c0c);
      margin-left: 14.5rem;
      color: #004a80;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 52.8px */
    }
  }

  .svg_container {
    display: flex;
    margin-top: -3rem;
    justify-content: center;

    svg {
      transform: scale(.7);
    }
  }

  @media (max-width: 900px) {
    .content_wrapper {
      margin-top: 2rem;
    }

    .svg_container {
      svg {
        transform: scale(.9);
      }
    }
  }
`;
