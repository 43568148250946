import React from "react";

import { Gallery } from "./partials/slider_rotate";
import { PlanoEstadoIcon } from "./svgs/plano_estado";

import { PlanoGestaoIcon } from "./svgs/plano_gestao";
import { PlanoSetorialIcon } from "./svgs/plano_setorial";

import { useMediaQuery, query } from "@/hooks/useMediaQuery";
import { PlanoEstadoLightIcon } from "./svgs/plano_estado_light";

import { PlanoGestaoLightIcon } from "./svgs/plano_gestao_light";
import { PlanoOrcamentarioIcon } from "./svgs/plano_orcamentario";

import { PlanoInstitucionalIcon } from "./svgs/plano_institucional";
import { PlanoSetorialLightIcon } from "./svgs/plano_setorial_light";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useRotateSliderValue } from "@/services/zustand/slider_rotate";
import { Main, Container, SvgContainer, SiderContainer } from "./styles";

import { PlanoOrcamentarioLightIcon } from "./svgs/plano_orcamentario_light";
import { PlanoInstitucionalLightIcon } from "./svgs/plano_institucional_light";

interface IArrayTypeOfPlans {
  id: number;
  title: string;
  refs: string[];
  description: string;
  side_icon: JSX.Element;
  center_icon: JSX.Element;
}

export const arrayTypeOfPlans: IArrayTypeOfPlans[] = [
  {
    id: 0,
    title: "Plano de Estado",
    refs: ["estado", "estado_light"],
    center_icon: <PlanoEstadoIcon />,
    side_icon: <PlanoEstadoLightIcon />,
    description: `Plano de longo prazo, instituído por lei ou decreto, 
    com período de vigência de 10 anos ou mais, com caráter permanente 
    e sistêmico. Objetiva ordenar o desenvolvimento sustentável e integrado 
    das políticas públicas em atendimento às legislações específicas ou Acordos 
    Nacionais e Internacionais. Sua elaboração envolve o desenvolvimento de 
    estudos técnicos, cenários e tendências que devem nortear a construção 
    das políticas de estado.
    `,
  },
  {
    id: 1,
    title: "Plano Orçamentário",
    refs: ["orcamentario", "orcamentario_light"],
    center_icon: <PlanoOrcamentarioIcon />,
    side_icon: <PlanoOrcamentarioLightIcon />,
    description: `Instituído por lei, orienta os gestores públicos na 
    execução dos gastos e na aplicação dos investimentos. Deve estabelecer 
    de forma regionalizada os objetivos e metas da administração pública 
    municipal para as despesas de capital e outras delas decorrentes, assim 
    como para os programas de duração continuada.`,
  },
  {
    id: 2,
    refs: ["gestao", "gestao_light"],
    title: "Plano de Gestão",
    center_icon: <PlanoGestaoIcon />,
    side_icon: <PlanoGestaoLightIcon />,
    description: `Previsto na Lei Orgânica do Município, direciona as ações 
    da Prefeitura no prazo de quatro anos, período equivalente ao mandato do 
    chefe do Poder Executivo, em alinhamento com as aspirações de longo prazo 
    estabelecidas para a cidade nos Planos de Estado, com base nas prioridades 
    da Administração e em análises técnicas. Define as iniciativas, metas 
    estratégicas e projetos.`,
  },
  {
    id: 3,
    refs: ["setorial", "setorial_light"],
    title: "Plano Setorial",
    center_icon: <PlanoSetorialIcon />,
    side_icon: <PlanoSetorialLightIcon />,
    description: `Instrumento que estabelece o planejamento detalhado para
    políticas públicas específicas, instituído por lei ou decreto, atendendo 
    à legislação pertinente.`,
  },
  {
    id: 4,
    refs: ["institucional", "institucional_light"],
    title: "Plano Institucional",
    center_icon: <PlanoInstitucionalIcon />,
    side_icon: <PlanoInstitucionalLightIcon />,
    description: `Instrumento que estabelece os objetivos e metas a serem 
    alcançados pelo órgão ou entidade e traça os planos de ação nos níveis 
    tático, no curto e médio prazos, em alinhamento com todos os planos 
    traçados para a Cidade.`,
  },
];

export const TypesOfPlans: React.FC = () => {
  const { isMobile } = useMediaQuery(query);
  const [currentItem, setCurrentItem] = React.useState(
    {} as Record<string, any>
  );
  const [typeOfPlans, setTypeOfPlans] = React.useState<IArrayTypeOfPlans[]>([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const selectedItem = useRotateSliderValue((props) => props.selectedItem);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex: any) =>
      prevIndex === 0 ? typeOfPlans.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex: any) =>
      prevIndex === typeOfPlans.length - 1 ? 0 : prevIndex + 1
    );
  };

  React.useEffect(() => {
    setTypeOfPlans(arrayTypeOfPlans);
  }, []);

  React.useEffect(() => {
    typeOfPlans?.forEach((item) => {
      if (item.refs.some((i) => i === selectedItem)) {
        setCurrentItem(item);
      }
    });
  }, [selectedItem, typeOfPlans]);

  return (
    <Main id='home_planos'>
      <Container>
        <div className="title_wrapper">
          <p>Tipos de planos</p>
        </div>
        {!isMobile && <Gallery />}
        {isMobile && (
          <div className="content_wrapper">
            <div onClick={handlePrevious}>
              <ArrowBackIosNewIcon sx={{ fill: "#004a80" }} />
            </div>
            <SvgContainer index={2}>
              {typeOfPlans[currentIndex]?.center_icon}
            </SvgContainer>
            <div onClick={handleNext}>
              <ArrowForwardIosIcon sx={{ fill: "#004a80" }} />
            </div>
          </div>
        )}
        <SiderContainer>
          {!isMobile && (
            <div className="content_item">
              <div className="description_wrapper">
                <p> {currentItem?.title}</p>
                <span> {currentItem?.description}</span>
              </div>
            </div>
          )}
          {isMobile && (
            <div className="content_item">
              <div className="description_wrapper">
                <p> {typeOfPlans[currentIndex]?.title}</p>
                <span> {typeOfPlans[currentIndex]?.description}</span>
              </div>
            </div>
          )}
        </SiderContainer>
      </Container>
    </Main>
  );
};
