import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding: 0 2rem;
  margin: -1rem 0 4rem 0;

  .col-1 {
    width: 37%;
    margin-left: 6rem;
    display: flex;
    align-items: center;
    justify-content: right;

    svg {
      transform: scale(0.8);
    }
  }

  .col-2 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: left;
    transform: scale(0.8);

  }

  @media (max-width: 820px) {
    justify-content: center;
    flex-direction: column;

    .col-1 {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .col-2 {
      width: 90%;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 90%;
  height: 439px;
  border-radius: 32px;
  background: var(
    --degrade-cinza,
    linear-gradient(138deg, #fff 20.45%, #fafafa 52.11%, #f0f0f0 80.12%)
  );
  box-shadow: -10px -4px 16px 0px rgba(0, 0, 0, 0.16),
    10px 4px 16px 0px rgba(0, 0, 0, 0.15);

  .title_wrapper {
    width: 60% !important;

    p {
      color: var(--primary-text-black, #0b0c0c);

      /* calibri bold/h1 bold */
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 52.8px */
    }
  }

  .description_wrapper {
    width: 60% !important;
    p {
      color: var(--primary-text-black, #0b0c0c);

      /* calibri regular/subtitulo regular */
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 26.4px */
    }
  }

  .button_wrapper {
    width: 60%;

    button {
      display: flex;
      width: 100%;
      border: none;
      padding: 20px 22px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 40px;
      background: var(--primary-links-blue, #004a80);

      /* elevation/2 */
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }

    p {
      color: var(--primary-white, #fff);

      /* calibri bold/corpo bold */
      font-size: 19px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%; /* 24.7px */
    }

    &:hover {
      p {
        color: #004a80;
      }
    }
  }

  @media (max-width: 820px) {
    height: auto;
    padding: 1rem;
    width: fit-content;
  }

  
  @media (max-width: 520px) {
    .button_wrapper {
      width: 80%;
    }
  }

  .title_wrapper {
    width: 100%;

    p {
      text-align: center;
    }
  }

  .description_wrapper {
    width: 100%;

    p {
      text-align: center;
    }
  }
`;
