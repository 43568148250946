export const PlanoEstadoLightIcon = () => {
  return (
    <svg
      width="422"
      height="387"
      viewBox="0 0 422 387"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666016"
        y="33"
        width="396"
        height="342"
        rx="8"
        fill="url(#paint0_linear_3035_62160)"
      />
      <rect
        x="22.666"
        y="12"
        width="396"
        height="342"
        rx="8"
        stroke="url(#paint1_linear_3035_62160)"
        stroke-width="6"
      />
      <path
        d="M154.959 248.458V193.916H170.542V248.458H154.959ZM201.709 248.458V193.916H217.292V248.458H201.709ZM131.584 279.624V264.041H287.417V279.624H131.584ZM248.459 248.458V193.916H264.042V248.458H248.459ZM131.584 178.333V162.749L209.501 123.791L287.417 162.749V178.333H131.584ZM166.257 162.749H252.744L209.501 141.322L166.257 162.749Z"
        fill="url(#paint2_linear_3035_62160)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3035_62160"
          x1="92.241"
          y1="84.3"
          x2="243.134"
          y2="367.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2EFFA " />
          <stop offset="1" stop-color="#89E2FA" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3035_62160"
          x1="114.241"
          y1="63.3"
          x2="265.134"
          y2="346.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2EFFA" />
          <stop offset="1" stop-color="#004A80"/>
        </linearGradient>
        <linearGradient
          id="paint2_linear_3035_62160"
          x1="149.115"
          y1="138.4"
          x2="252.355"
          y2="252.354"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.145833" stop-color="white" />
          <stop offset="0.598958" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#F0F0F0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
