import React from "react";
import { Container } from "./styles";
import { useRotateSliderValue } from "@/services/zustand/slider_rotate";
import { useRouter } from "next/router";

export const Gallery = () => {
  let [carouselItems, setCarouselItems] = React.useState<any[]>([]);
  const galleryContainer = React.useRef<any>(null);
  const galleryControlsContainer = React.useRef<any>(null);
  const selectedItem = useRotateSliderValue((props) => props.selectedItem);
  const router = useRouter();

  React.useEffect(() => {
    if (galleryContainer.current) {
      const items = document.querySelectorAll(
        ".gallery-item"
      ) as unknown as any[];
      setCarouselItems(Array.from(items));
    }
  }, [galleryContainer]);

  React.useEffect(() => {
    // useControls();
  }, []);

  const updateGallery = React.useCallback(() => {
    carouselItems.forEach((el: any) => {
      el?.classList.remove(
        "gallery-item-1",
        "gallery-item-2",
        "gallery-item-3",
        "gallery-item-4",
        "gallery-item-5"
      );
    });

    [...carouselItems].slice(0, 5).forEach((el: any, i) => {
      el?.classList.add(`gallery-item-${i + 1}`);
    });
  }, []);

  const setCurrentState = React.useCallback(
    (direction: any) => {
      if (carouselItems.length < 1) {
        carouselItems = Array.from(galleryContainer.current.childNodes);
      }

      if (direction === "previous") {
        const lastItem = carouselItems.pop();
        carouselItems.unshift(lastItem);
      } else {
        const firstItem = carouselItems.shift();
        carouselItems.push(firstItem);
      }

      carouselItems?.forEach((item, index) => {
        item.className = `gallery-item gallery-item-${index + 1}`;
      });

      carouselItems?.forEach((item) => {
        if (item.className === "gallery-item gallery-item-3") {
          useRotateSliderValue.setState({
            selectedItem: item.src.split("/")[4].split(".")[0],
          });
        }
      });

      updateGallery();
    },
    []
  );

  return (
    <Container>
      <div className="gallery">
        <div className="gallery-container" ref={galleryContainer}>
          <img
            className="gallery-item gallery-item-1"
            src={
              selectedItem === "estado_light"
                ? "/images/estado.png"
                : "/images/estado_light.png"
            }
            data-index="1"
          />
          <img
            className="gallery-item gallery-item-2"
            src={
              selectedItem === 'institucional_light' 
              ? "/images/institucional.png"
              : "/images/institucional_light.png"
            }
            data-index="2"
          />
          <img
            className="gallery-item gallery-item-3"
            src={
              selectedItem === "gestao_light"
                ? " /images/gestao.png"
                : " /images/gestao_light.png"
            }
            data-index="3"
          />
          <img
            className="gallery-item gallery-item-4"
            src={
              selectedItem ==='setorial_light'
              ? "/images/setorial.png"
              : "/images/setorial_light.png"
            }
            data-index="4"
          />
          <img
            className="gallery-item gallery-item-5"
            src={
              selectedItem === "orcamentario_light"
                ? "/images/orcamentario.png"
                : "/images/orcamentario_light.png"
            }
            data-index="5"
          />
        </div>
      </div>
      <div className="gallery-controls" ref={galleryControlsContainer}>
        <div
          onClick={(e) => setCurrentState("previous")}
          className="gallery-controls-previous"
        />
        <div
          onClick={(e) => setCurrentState("next")}
          className="gallery-controls-next"
        />
      </div>
    </Container>
  );
};
