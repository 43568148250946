import React from "react";
import { Icon } from "./icon";
import Image from "next/image";
import BannerImage from "/public/homeV2/bg.png";
import { Container, ImageWrapper, InfoBanner, ButtonFidMore } from "./styles";
import { useRouter } from "next/router";
import { Divider } from "@mui/material";

export const Banner: React.FC = () => {
  const router = useRouter();
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  function handleResize() {
    setWindowWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <ImageWrapper>
        <Image
          fill={true}
          quality={100}
          loading="eager"
          priority={true}
          className="image"
          src={BannerImage.src}
          alt="imagem do rio de janeiro"
        />
        <InfoBanner width={String(windowWidth)}>
          <div className="col-2">
            <div className="title_wrapper">
              <p>INTERPLAN.RIO</p>
            </div>
            <div className="description_wrapper">
              <p>
                O INTERPLAN é uma plataforma que reúne os planos municipais
                vigentes, permitindo
                consulta aos diferentes instrumentos de planejamento das
                políticas públicas do município do Rio de Janeiro. O objetivo
                deste portal é fortalecer a integração do planejamento
                municipal, assim como divulgar e dar transparência aos Planos e
                Programas em busca da implementação do planejamento sustentável
                da cidade.
              </p>
            </div>
          </div>
          <Divider orientation="vertical" flexItem variant="middle" />
          <div className="col-3">
            <div className="description_button">
              <p>Conheça os instrumentos de planejamento da cidade:</p>
            </div>
            <ButtonFidMore onClick={() => router.push("/planos")}>
              <p>Saiba mais sobre os planos</p>
            </ButtonFidMore>
          </div>
        </InfoBanner>
      </ImageWrapper>
    </Container>
  );
};
