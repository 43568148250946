import styled from "styled-components";

export const Container = styled.div`
  width: 96%;
  display: flex;
  margin-bottom: 1rem;

  max-width: 972px;

  padding: 15px;
  cursor: pointer;

  overflow: hidden;
  align-items: center;

  border-radius: 12px;
  /* height: 400.3px; */

  position: relative;
  height: 550.3px;

  flex-direction: column;
  justify-content: space-between;

  background: white;
  /* background-color: ${(props) => props.theme.colors.home_card_bg_bage}; */

  box-shadow: 8px 7px 16px 0px rgba(0, 0, 0, 0.16);

  ::before {
    content: "";
    position: absolute;
    top: 0;

    left: 0;
    right: 0;

    bottom: 0;
    border: 10px solid transparent;

    border-image: linear-gradient(
        to right,
        ${(props) => props.theme.colors.home_card_border} 80%,
        #fbfbfb
      )
      1;

    border-radius: inherit;
  }

  @media (max-width: 1024px) {
    min-height: 651.3px;
    max-height: 651.3px;
  }

  @media (max-width: 820px) {
    min-height: 910.3px;
    max-height: 910.3px;
  }
`;

interface IContainerTitle {
  status: number;
}

export const ContainerTitle = styled.div<IContainerTitle>`
  width: 100%;
  height: 60px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-size: 22px;
    font-weight: 400;

    line-height: 120%;

    font-style: normal;
    text-align: center;

    color: var(--primary-text-black, #0b0c0c);
  }

  h1 {
    font-size: 22px;
    font-weight: 700;

    margin-top: 1rem;

    min-height: 80px;
    line-height: 120%;

    text-align: center;

    font-style: normal;
    color: var(--primary-links-blue, #004a80);
  }

  @media (max-width: 820px) {
    padding: 32px 15px;
    h1 {
      font-size: 22px;
    }
  }

  .status_wrapper {
    width: 120%;
    display: flex;
    justify-content: end;

    padding: 3px 6px;
    align-items: flex-start;
  }

  .status_item {
    border-radius: 100px;
    padding: 0.5rem 0.7rem;
    background: ${({ status }) => status === 1 ?  '#247d09' : '#E33636'};

    p {
      color: var(--primary-white, #fff);
      font-feature-settings: "clig" off, "liga" off;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 138.462% */
      letter-spacing: 0.16px;
    }
  }

  @media (max-width: 960px) {
    .status_wrapper {

      width: 100%;
    }
  }

  @media (max-width: 480px) {
    .status_wrapper {
      width: 100%;
    }
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  padding: 31px;

  min-width: 100%;

  min-height: 460px;
  align-items: center;

  flex-direction: column;
  justify-content: space-around;

  background: ${(props) => props.theme.colors.home_card_bg};

  svg {
    scale: 1.3;
  }
  svg stop:first-child {
    stop-color: ${({ theme }) => theme.colors.button};
  }
  svg stop:nth-child(2) {
    stop-color: ${({ theme }) => theme.colors.footer_text_last};
  }

  p {
    font-size: 18px;
    margin-top: 37px;

    font-weight: 300;
    line-height: 120%;
    text-align: center;
    font-style: normal;
    color: ${(props) => props.theme.colors.text};
  }

  @media (max-width: 820px) {
    min-height: 690px;
    p {
      font-size: 20px;
    }
  }
`;
