import {
  Main,
  Container,
  Description,
  ButtonFidMore,
  ContainerPlanos,
  ContainerDescriptions,
} from "./styles";
import React from 'react';
import Link from "next/link";
import { Card } from "./partials/card";
import { Pagination } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { PlansService } from "@/services/endpoints/plans";
import { AddSvgToData } from "@/utils/SvgDictionaries/addSvgToData";
import { useRouter } from "next/router";

export function Planos() {
  const [index, setIndex] = useState(0);
  const [plans, setPlans] = useState<any[]>([]);
  const plansService = new PlansService();

  // Função para buscar todos os planos
  const handleGetAllPlans = useCallback(() => {
    plansService.getLastFive()
      .then((res) => {
        const { data } = res.data as any;
        const newArray = AddSvgToData(data);
        setPlans(newArray);
      })
      .catch((error) => {
        console.error('Error fetching plans:', error);
      });
  }, [plansService]);

  const router = useRouter();
  useEffect(() => {
    if (router.pathname.split('/').filter(item => item != "").length == 0)
      handleGetAllPlans();
  }, [router.pathname]);

  return (
    <Main>
      <Container>
        <ContainerDescriptions>
          <h1>PLANOS</h1>
          <Description>
            Os planos são instrumentos de planejamento de políticas públicas
            para a cidade do Rio de Janeiro e partem da premissa
            de que uma estratégia bem concebida resulta em uma implementação
            bem-sucedida nos diversos setores em que a prefeitura atua.
          </Description>
          <Description>
            Por exemplo, o Plano Municipal de Saúde organiza as ações da prefeitura 
            para melhoria do serviço de saúde no município, influenciando desde a forma 
            como é gerida a secretaria do tema até a instalação de uma unidade de saúde 
            no seu bairro. Ao lado, é possível visualizar os cinco últimos planos adicionados à plataforma.
          </Description>
          <Link href="/planos">
            <ButtonFidMore>
              <p>Saiba mais sobre os planos</p>
            </ButtonFidMore>
          </Link>
        </ContainerDescriptions>
        <ContainerPlanos>
          <Card plano={plans[index]} />
          <Pagination
            count={plans.length}
            size="large"
            color="primary"
            onChange={(e, value) => setIndex(value - 1)}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </ContainerPlanos>
      </Container>
    </Main>
  );
}