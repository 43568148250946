export const PlanoSetorialIcon = () => {
  return (

  <svg width="422" height="387" viewBox="0 0 422 387" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.666016" y="33" width="396" height="342" rx="8" fill="url(#paint0_linear_3035_62301)"/>
  <g clip-path="url(#clip0_3035_62301)">
  <path d="M273 131V212H228V257H147V131H273ZM273 113H147C137.1 113 129 121.1 129 131V257C129 266.9 137.1 275 147 275H237L291 221V131C291 121.1 282.9 113 273 113ZM210 212H165V194H210V212ZM255 176H165V158H255V176Z" fill="white"/>
  </g>
  <rect x="22.666" y="12" width="396" height="342" rx="8" stroke="url(#paint1_linear_3035_62301)" stroke-width="6"/>
  <defs>
  <linearGradient id="paint0_linear_3035_62301" x1="92.241" y1="84.3" x2="243.134" y2="367.067" gradientUnits="userSpaceOnUse">
  <stop stop-color="#004A80"/>
  <stop offset="1" stop-color="#00C0F3"/>
  </linearGradient>
  <linearGradient id="paint1_linear_3035_62301" x1="114.241" y1="63.3" x2="265.134" y2="346.067" gradientUnits="userSpaceOnUse">
  <stop stop-color="#D2EFFA"/>
  <stop offset="1" stop-color="#89E2FA"/>
  </linearGradient>
  <clipPath id="clip0_3035_62301">
  <rect width="216" height="216" fill="white" transform="translate(102 86)"/>
  </clipPath>
  </defs>
  </svg>
  )
}
