import styled from "styled-components";

export const Container = styled.div`
/* background: red; */
`;

export const ImageWrapper = styled.div`
  width: 100%;
  margin: -0.8rem 0 0 0;
  position: unset !important;

  .image {
    object-fit: contain;
    width: 100% !important;
    height: unset !important;
    position: relative !important;
  }
`;

interface IInfoBannerProps {
  width: string;
}

export const InfoBanner = styled.div<IInfoBannerProps>`
  top: 100%;
  left: 50%;

  gap: 24px;
  z-index: 1;
  height: fit-content;
  display: flex;
  background: #fff;
  position: relative;
  
  transform: translate(-50%, -70%) scale(.8);
  
  box-shadow: 8px 7px 16px 0px rgba(0, 0, 0, 0.16);

  width: ${({ width }) => `calc(${width}px - 230px)`};
  
  -moz-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 2px 13px 0px rgba(0, 0, 0, 0.5);

  border-radius: 8px var(--contador0, 0px) var(--contador0, 0px) 8px;

  .title_wrapper {
      width: 410px;
      position: fixed;
      padding: 1rem;
      background: #004a80;
      transform: translate(90%, -121%) skewX(-20deg);;


      p {
        transform: skewX(20deg);
        font-size: 38px;
        font-weight: 700;
        letter-spacing: .5rem;
        line-height: 110%;
        font-style: normal;
        color: white;
      }
    }


  .col-1 {
    width: 10%;
    display: flex;
    justify-content: center;
    padding: 1.5rem 0rem 0rem 1rem;
  }

  .col-2 {
    gap: 8px;
    width: 60%;
    padding: 1rem;
    display: flex;
    flex-direction: column;


    .description_wrapper {
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 120%;
        font-style: normal;
        color: var(--primary-text-black, #0b0c0c);
      }
    }
  }

  .col-3 {
    width: 30%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .description_button {
      p {
        font-size: 26px;
        font-weight: 700;
        line-height: 120%;
        font-style: normal;
        text-align: center;
        color: var(--primary-links-blue, #004a80);
      }
    }

    button {
      border: none;
      display: flex;
      padding: 20px 22px;
      align-items: center;
      align-self: stretch;
      border-radius: 40px;
      flex-direction: column;
      justify-content: center;
      background: var(--primary-links-blue, #004a80);
      /* elevation/2 */
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

      p {
        color: var(--primary-white, #fff);

        /* calibri bold/corpo bold */
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%; /* 24.7px */
      }

      &:hover {
        background: #D2EFFA;

        p {
          color: #004a80;
        }
      }
    }
  }

  @media (min-width: 1900px) {
    width: calc(1920px - 200px);
  }

  @media (max-width: 1200px) {
    width: ${({ width }) => `calc(${width}px - 100px)`};
  }

  @media (max-width: 960px) {
    flex-direction: column;
    width: ${({ width }) => `calc(${width}px - 100px)`};


    .col-1 {
      display: none;
    }

    .col-2 {
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .col-3 {
      width: 100%;
    }
  }

  @media (max-width: 780px) {
    transform: translate(-50%, -10%);
    width: 90%;
    /* margin-top: 13rem; */
  }


`;

export const ButtonFidMore = styled.div`
  display: flex;
  width: 100%;

  height: 65px;
  display: flex;

  margin-top: 5%;
  padding: 20px 22px;

  text-align: center;
  text-align: center;

  align-items: center;
  align-self: stretch;

  border-radius: 40px;
  align-items: center;

  justify-content: center;
  justify-content: center;

  transition: background-color 0.3s;
  background: ${(props) => props.theme.colors.secundary};

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  p {
    color: ${(props) => props.theme.colors.primary};
    font-size: 19px;
    font-weight: 700;
    line-height: 130%;
    font-style: normal;
    margin-right: 10px;
  }

  &:hover {
    cursor: pointer;

    background-color: #89e2fa;

    p {
      color: #004a80;
    }
  }

  svg path {
    fill: ${(props) => props.theme.colors.primary};
  }

  @media (max-width: 820px) {
    width: 100%;
  }
`;
