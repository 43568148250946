import React from "react";
import { Container } from "./styles";
import { Banner } from "./partials/Banner";
import { Planos } from "./partials/planos";
import { ValueOffer } from "./partials/value_offer";
import { TypesOfPlans } from "./partials/type_of_plans";
import { InteractiveMap } from "./partials/interactive_map";

export const HomeV2: React.FC = () => {
  React.useEffect(() => {
    const previousRoute = localStorage.getItem("previousRoute");

    if (previousRoute === "/dashboard/painel_de_visualizacao") {
      setTimeout(function () {
        window.scrollTo(0, 2400);
        
        localStorage.removeItem('previousRoute');
      }, 30000);
    }
  }, []);

  return (
    <Container>
      <Banner />
      <Planos />
      <ValueOffer />
      <TypesOfPlans />
      <InteractiveMap />
    </Container>
  );
};
