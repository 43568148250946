export const PlanoGestaoIcon = () => {
  return (
    <svg
      width="422"
      height="387"
      viewBox="0 0 422 387"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.666016"
        y="33"
        width="396"
        height="342"
        rx="8"
        fill="url(#paint0_linear_3035_62202)"
      />
      <rect
        x="22.666"
        y="12"
        width="396"
        height="342"
        rx="8"
        stroke="url(#paint1_linear_3035_62202)"
        stroke-width="6"
      />
      <g clip-path="url(#clip0_3035_62202)">
        <path
          d="M138.691 258.342V154L243.256 113.868V186.105H251.3C251.3 181.691 252.875 177.911 256.025 174.768C259.176 171.624 262.963 170.052 267.387 170.052C271.81 170.052 275.598 171.624 278.748 174.768C281.898 177.911 283.473 181.691 283.473 186.105V258.342H138.691ZM154.778 242.289H186.952V186.105H227.169V137.144L154.778 165.036V242.289ZM203.039 242.289H227.169V218.21H243.256V242.289H267.387V202.158H203.039V242.289Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3035_62202"
          x1="92.241"
          y1="84.3"
          x2="243.134"
          y2="367.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3035_62202"
          x1="114.241"
          y1="63.3"
          x2="265.134"
          y2="346.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2EFFA" />
          <stop offset="1" stop-color="#89E2FA" />
        </linearGradient>
        <clipPath id="clip0_3035_62202">
          <rect
            width="173"
            height="160"
            fill="white"
            transform="translate(123 114)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
