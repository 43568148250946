import styled, { css } from "styled-components";

export const Main = styled.main`
  width: 100%;
  display: flex;
  margin-top: -5rem;
  background: #f3f3f3;
  justify-content: center;

  @media (max-width: 900px) {
    width: 100%;
    display: flex;
    clip-path: none;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: fit-content;
  /* margin-top: -2rem; */
  background: #f3f3f3;
  padding: 90px 0 50px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title_wrapper {
    display: flex;
    width: 100%;
    margin-top: -3rem;

    p {
      color: var(--primary-text-black, #0b0c0c);
      text-align: center;
      margin-left: 14.5rem;
      color: #004a80;
      /* calibri bold/h1 bold */
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 52.8px */
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    display: flex;
    clip-path: none;
    padding: 90px 24px;
    flex-direction: column;
  }

  .content_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface ISvgContainerProps {
  index: number;
}

export const SvgContainer = styled.div<ISvgContainerProps>`
  display: flex;

  /* -webkit-transform: translateY(-50%) translateX(-50%) scale(0.8); */
  /* -moz-transform: translateY(-50%) translateX(-50%) scale(0.8); */
  /* -ms-transform: translateY(-50%) translateX(-50%) scale(0.8); */
  /* -o-transform: translateY(-50%) translateX(-50%) scale(0.8); */

  

  svg {
    cursor: pointer;
    width: 220px !important;
    height: 287px !important;
  }

  ${({ index }) =>
    index === 0 &&
    css`
      svg {
        /* transform: scale(0.5); */
        transform: translateX(0%) scale(0.5);
      }
    `};

  ${({ index }) =>
    index === 1 &&
    css`
      svg {
        transform: scale(0.8);
        margin: 0 0 0 -2rem;
      }
    `};

  ${({ index }) =>
    index === 2 &&
    css`
      svg {
        transform: scale(1);
        margin: 0 1rem;
      }
    `};

  ${({ index }) =>
    index === 3 &&
    css`
      svg {
        transform: scale(0.8);
        margin: 0 -2rem 0 0;
      }
    `};

  ${({ index }) =>
    index === 4 &&
    css`
      svg {
        transform: scale(0.5);
      }
    `};
`;

export const SiderContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -2rem;

  .content_item {
    margin-top: 1rem;
    width: fit-content;
  }

  .description_wrapper {
    gap: 8px;
    display: flex;
    min-height: 250px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      color: var(--primary-links-blue, #004a80);
      text-align: center;
      /* calibri bold/titulo bold */
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%; /* 31.2px */
    }

    span {
      color: var(--primary-links-blue, #004a80);
      text-align: center;

      /* calibri regular/subtitulo regular */
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 26.4px */
    }
  }

  @media (max-width: 960px) {
    .description_wrapper {
      span {
        font-size: 16px !important;
      }
    }
  }
`;
