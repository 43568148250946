import React from 'react';
import { Container } from './styles';
// import { ValueOfferIcon } from './value_offer_desktop';
import { ValueOfferIcon } from './value_offer';
import { useMediaQuery, query } from '@/hooks/useMediaQuery';
import { ValueOfferMobileIcon } from './value_offer_mobile';

export const ValueOffer: React.FC = () => {
  const { isMobile } = useMediaQuery(query)

  return (
    <Container>
      <div className='content_wrapper'>
        <div className='title_wrapper'>
          <p>Proposta de valor</p>
        </div>
        <div className='svg_container'>
          {isMobile ? (
            <ValueOfferMobileIcon />
          ) : (
            <ValueOfferIcon />
          )}
        </div>
      </div>
    </Container>
  )
};